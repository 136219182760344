import { useEffect } from 'react'
import tw from 'twin.macro'

import FlexSection from '@components/FlexSection'
import Tabs from '@components/Tabs'
import { findComponent } from '@components/FlexPage'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_Tabs,
  WpPrograma_Acfpagefields_Content_Tabs
} from '@_types/graphql-types'

import slugify from '@sindresorhus/slugify'

type contentType =
  | WpPage_Acfpagefields_Content_Tabs
  | WpPrograma_Acfpagefields_Content_Tabs

const FlexTabs = ({ data, index }: { data: contentType; index: string }) => {
  const { abas, title, bgColor, tagTitle } = data

  useEffect(() => {
    componentLoaded(title)
  }, [])

  const formattedTabs = abas.map((aba, tabIndex) => {
    return {
      name: aba.title,
      id: slugify(aba.title),
      render: () => {
        if (!aba.content) {
          return <div></div>
        } else {
          return aba.content.map((item, contentIndex) => {
            return findComponent(item, (tabIndex + 1) * 10 + contentIndex, true)
          })
        }
      }
    }
  })

  const colors = findColors(bgColor)

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      alignTitle="center"
      colors={colors}
      id={title || ''}
      paddingTop
      paddingBottom
    >
      <Tabs items={formattedTabs} />
    </FlexSection>
  )
}

export default FlexTabs
