import tw, { styled } from 'twin.macro'
import { useState, createRef, forwardRef, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { useLocation } from '@reach/router'
import { useEventListener } from '@utils/hooks'
import queryString from 'query-string'

type TabItems = {
  name: string
  id: string
  render: () => React.ReactNode
}

const Tabs = ({ items }: { items: TabItems[] }) => {
  const [animating, setAnimating] = useState(false)
  const [activeTab, setActiveTab] = useState(items[0].id)

  const tabRefs = items.reduce((acc, item) => {
    acc[item.id] = createRef()
    return acc
  }, {})

  const location = useLocation()

  useEffect(() => {
    if (location.search) {
      const query = queryString.parse(location.search)
      query._tab && typeof query._tab === 'string' && setActiveTab(query._tab)
    }
  }, [location])

  // @ts-ignore
  useEventListener('change-tabs', e => {
    // @ts-ignore
    const tab = e.detail ? e.detail.tab : ''
    document.querySelector('.tabs-list').scrollIntoView()
    setActiveTab(tab)
  })

  return (
    <TabsContainer>
      <div>
        <ul className="tabs-list" role="tablist" aria-orientation="horizontal">
          {items.map(item => (
            <Tab
              key={item.id}
              item={item}
              ref={tabRefs[item.id]}
              animating={animating}
              startAnimating={() => setAnimating(true)}
              setActiveTab={setActiveTab}
              active={activeTab === item.id}
            />
          ))}
        </ul>
      </div>

      {items.map(item => (
        <motion.div
          key={item.id}
          initial="inactive"
          animate={activeTab === item.id ? 'active' : 'inactive'}
          variants={tabContentVariant}
        >
          {item.render()}
        </motion.div>
      ))}
    </TabsContainer>
  )
}

const TabsContainer = styled.div`
  .tabs-list {
    ${tw`sm:flex justify-evenly mb-6 lg:mb-8 mx-auto max-w-2xl`}

    &__item {
      ${tw`text-center mb-3 sm:mb-0`}
    }
    &__tab {
      ${tw`text-lemann-orange block font-medium text-sm border-2 border-lemann-orange rounded-full px-6 lg:px-8 py-3  cursor-pointer select-none`}
      ${tw`transition-all duration-200 ease-in`}

      &.active {
        ${tw`bg-lemann-orange text-gray-800 `}
      }
    }
  }
`

const tabContentVariant = {
  active: {
    display: 'block',
    opacity: 1,
    transition: {
      type: 'tween',
      delay: 0,
      duration: 0.3
    }
  },
  inactive: {
    display: 'none',
    opacity: 0
  }
}

interface ITab {
  active: boolean
  animating: boolean
  startAnimating: () => void
  item: TabItems
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}

const Tab = forwardRef<HTMLAnchorElement, ITab>(
  ({ active, item, animating, startAnimating, setActiveTab }, ref) => (
    <li className="tabs-list__item" key={`tab-${item.id}`}>
      <a
        data-id={item.id}
        className={`tabs-list__tab ${active ? 'active' : 'inactive'} ${
          animating ? 'animating' : ''
        }`}
        ref={ref}
        onClick={() => {
          startAnimating()
          setActiveTab(item.id)
        }}
      >
        {item.name}
      </a>
    </li>
  )
)

export default Tabs
